import { Container, Row, Col } from "react-bootstrap"

function UFOXHeader({ pagetitle }) {
  return (
    <Container>
      <Row>
        <Col id="title-cell" className="body-grid-cell">{ pagetitle }</Col>
      </Row>
    </Container>
  );
}

export default UFOXHeader;
