import { useContext, useEffect, useState } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { Button, Form, Alert } from "react-bootstrap"

import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

import { UFOXTitleContext } from "./App"

function ReportForm() {
  const [title, setTitle] = useContext(UFOXTitleContext)

  const [allShapes, setAllShapes] = useState([])
  const [allUFOXCountries, setAllUFOXCountries] = useState([])
  const [allCharacteristics, setAllCharacteristics] = useState([])

  const [shape, setShape] = useState(null)
  const [eventDate, setEventDate] = useState(null)
  const [country, setCountry] = useState(null)
  const [description, setDescription] = useState(null)
  const [duration, setDuration] = useState(null)
  const [city, setCity] = useState(null)
  const [state, setState] = useState(null)
  const [images, setImages] = useState(null)
  const [characteristics, setCharacteristics] = useState([])

  useEffect(() => setTitle("UFOX Report Form"))

  useEffect(() => {
      fetch(process.env.REACT_APP_UFOX_API_URL + "/api/shapes", { credentials: "include" })
        .then((response) => {
          if (!response.ok) {
            console.log("Error retrieving shapes.")
            console.log(response)
            return []
          }

          response.json().then((all_shapes_json) => {
            setAllShapes([""].concat(all_shapes_json))
          })
        })
  }, [])

  useEffect(() => {
      fetch(process.env.REACT_APP_UFOX_API_URL + "/api/countries", { credentials: "include" })
        .then((response) => {
          if (!response.ok) {
            console.log("Error retrieving countries.")
            console.log(response)
            return []
          }

          response.json().then((all_ufox_countries_json) => {
            setAllUFOXCountries([""].concat(all_ufox_countries_json))
          })
        })
  }, [])

  useEffect(() => {
      fetch(process.env.REACT_APP_UFOX_API_URL + "/api/characteristics", { credentials: "include" })
        .then((response) => {
          if (!response.ok) {
            console.log("Error retrieving countries.")
            console.log(response)
            return []
          }

          response.json().then((all_characteristics_json) => {
            console.log(all_characteristics_json)
            setAllCharacteristics(all_characteristics_json)
          })
        })
  }, [])

  function submitReport(evt) {
    evt.preventDefault()
    console.log("Submitting report!")
    var object_to_submit = {
        name: "someone",
        hatSize: "12",
        eventDate: eventDate,
        duration: duration,
        country: country,
        shape: shape,
        description: description,
        city: city,
        state: state,
        characteristics: characteristics,
        images: []
    }
    if (images) {
        for (var i = 0; i < images.length; i++) {
            console.log("Image " + i)
            const image = images[i]
            const imageReader = new FileReader();
            imageReader.onload = function() {
                const imageObj = {
                    "name": image.name,
                    "size": image.size,
                    "type": image.type,
                    "data": imageReader.result
                }
                object_to_submit.images.push(imageObj)
                console.log("Pushing imageObj")
                console.log("Processed " + object_to_submit.images.length + " of " + images.length)
                if (object_to_submit.images.length == images.length) {
                    postReport(object_to_submit)
                }
            }
            console.log("Calling readAsDataURL")
            imageReader.readAsDataURL(image)
        }
    } else {
      console.log("Hey it's cool thx")
      postReport(object_to_submit)
    }
  }

  function postReport(report) {
    console.log("New report:")
    console.log(report)
    fetch(process.env.REACT_APP_UFOX_API_URL + "/api/submitReport",
      {
        credentials: "include",
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify(report)
      }
    ).then((response) => {
      console.log("Response to report submission is:")
      console.log(response)
      response.text().then((text) => {
        console.log(text)
      });
    });
  }

  function characteristicChanged(evt) {
      console.log(evt)
      console.log(evt.target.value)
      console.log(evt.target.checked ? "IT IS CHECKED" : "No, get rid of it.")
      if (evt.target.checked) {
          setCharacteristics([... characteristics, evt.target.value])
      } else {
          setCharacteristics([... characteristics].filter(x => x !== evt.target.value))
      }
  }

  return (
    <Container>
      <Row>
        <Col md={4} style={{ textAlign: "left" }}>
            <div>Report a UFO</div>
            <Form action="#" onSubmit={ submitReport }>
                <Form.Group className="mb-3" controlId="eventDateField">
                  <Form.Label>Event Date</Form.Label>
                  <DateRangePicker initialSettings={{
                      timePicker: true,
                      singleDatePicker: true,
                      showDropdowns: true,
                      startDate: {eventDate},
                      locale: {
                        format: 'M/DD/YYYY hh:mm A',
                      }
                    }}
                    onApply={ (e, picker) => setEventDate(picker.startDate) }>

                    <Form.Control name="eventDate" type="text" />
                  </DateRangePicker>
                </Form.Group>

                <Form.Group className="mb-3" controlId="durationField">
                  <Form.Label>Duration</Form.Label>
                  <Form.Control
                    type="text"
                    maxLength="25"
                    onChange={e => setDuration(e.target.value) }>
                  </Form.Control>
                </Form.Group>

                <Form.Group className="mb-3" controlId="countryField">
                  <Form.Label>Country</Form.Label>
                  <Form.Select
                    name="country"
                    required
                    onBlur={ e => setCountry(e.target.value) }
                    onChange={ e => setCountry(e.target.value) }>
                    { allUFOXCountries.map((country, idx) =>
                        <option key={idx} value={country}>{country}</option>
                    )}
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mb-3" controlId="cityField">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type="text"
                    maxLength="60"
                    onChange={ e => setCity(e.target.value) }>
                  </Form.Control>
                </Form.Group>

                <Form.Group className="mb-3" controlId="stateField">
                  <Form.Label>State/Province</Form.Label>
                  <Form.Control
                    type="text"
                    maxLength="60"
                    onChange={ e => setState(e.target.value) }>
                  </Form.Control>
                </Form.Group>

                <Form.Group className="mb-3" controlId="shapeField">
                  <Form.Label>Shape</Form.Label>
                  <Form.Select
                    name="shape"
                    required
                    onBlur={ e => setShape(e.target.value) }
                    onChange={ e => setShape(e.target.value) }>
                    { allShapes.map((shape, idx) =>
                        <option key={idx} value={shape}>{shape}</option>
                    )}
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mb-3" controlId="descriptionField">
                  <Form.Label>Description</Form.Label>
                  <Form.Control as="textarea"
                    name="description"
                    type="text"
                    placeholder="I saw something I can't explain."
                    onChange={e => setDescription(e.target.value) }/>
                </Form.Group>

                <Form.Group className="mb-3" controlId="characteristicsField">
                  <Form.Label>Characteristics</Form.Label>
                    { allCharacteristics.map((a,i) => (
                        <Form.Check
                          label={a[0]}
                          value={a[0]}
                          onChange={e => characteristicChanged(e) }/>
                    ))}
                </Form.Group>

                <Form.Group className="mb-3" controlId="imagesField">
                  <Form.Label>Images</Form.Label>
                  <Form.Control
                    name="images"
                    type="file"
                    multiple
                    onChange={e => setImages(e.target.files) }/>
                </Form.Group>

                <Button className="btn btn-primary btn-large centerButton" type="submit">Send</Button>
            </Form>
            </Col>
        </Row>
        </Container>
  );
}

export default ReportForm;
