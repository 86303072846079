import { useContext, useEffect, useState } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { Button } from "react-bootstrap"

import { UFOXTitleContext, UFOXUserContext } from './App'

function Profile() {
  const [title, setTitle] = useContext(UFOXTitleContext)
  useEffect(() => setTitle("UFOX User Profile"));
  return (
    <User />
  );
}

function User() {
    const [ user, setUser ] = useContext(UFOXUserContext)

    function doLogout(evt) {
      evt.preventDefault()
      console.log("I'll do the logout. Sure I will.");
      fetch(process.env.REACT_APP_UFOX_API_URL + "/api/logout", {credentials: "include"})
          .then((response) => console.log(response))
          setUser({})
          // .then(() => window.location.reload(true))
    }

    if (!user) {
        return "No user"
    } else {
        return (
          <Container>
            <Row>
              <Col md={4} style={{ textAlign: "left" }}>
                <b>Name:</b> {user.name}<br />
                <b>Email:</b> {user.email}<br />
                <b>Hat Size:</b> {user.hat_size}<br />
              </Col>
              <Col md={4}>
                <Button variant="outline-dark" onClick={(e) => doLogout(e)}>Logout</Button>
              </Col>
              <Col md={4} />
            </Row>
          </Container>
        )
    }
}


export default Profile;
