import { useEffect, useState } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { CountGraph } from "./MainGraphGrid"

import './graphs.css'

function MultiGraphTest() {
    const [graphConfigs, setGraphConfigs] = useState([])

    useEffect(() => {
        fetch("/graph_configs.json")
            .then((response) => {
                if (!response.ok) {
                    console.log("Error retrieving graph configs.")
                    console.log(response)
                    return []
                }

                response.json().then((graph_configs_json) => {
                    console.log("Retrieved graph configs:")
                    console.log(graph_configs_json)
                    setGraphConfigs(graph_configs_json)
                });
            })
    },[]);

    return (
      <Container>
        <Row>
        { graphConfigs.map((config, idx) =>
            <Col md={6}>
                <CountGraph 
                    data_url={ config.data_url } 
                    dom_id={config.dom_id}
                    field_name={ config.field_name }
                    color={ config.color }
                    title={ config.title }
                    x_scale_type={ config['x_scale_type'] }
                />
            </Col>
        )}
        </Row>
      </Container>
    );
}

export default MultiGraphTest;

