import { useContext, useEffect, useState } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { Link, useParams } from 'react-router-dom'

import { UFOXTitleContext } from "./App"

function NUFORCAdmin() {
  const [title, setTitle] = useContext(UFOXTitleContext)

  const [nuforcIndexes, setNuforcIndexes] = useState([])
  const indexURL = useParams()['index_url']

  useEffect(() => setTitle("NUFORC Admin"))

  useEffect(() => {
      fetch(process.env.REACT_APP_UFOX_API_URL + "/api/nuforc-indexes", { credentials: "include" })
        .then((response) => {
          if (!response.ok) {
            console.log("Error retrieving NUFORC indexes.")
            console.log(response)
            return []
          }

          response.json().then((nuforc_indexes_json) => {
            setNuforcIndexes(nuforc_indexes_json)
          })
        })
  }, [])

  return (
    <Container>
      <Row>
        <Col md={3}>
          <table>
          <thead>
          <tr>
            <th>Date</th>
            <th>Status</th>
          </tr>
          </thead>
          <tbody>
          { nuforcIndexes.map((nuforcIndex, idx) => {
             const detailUrl = "/nuforc-admin/" + nuforcIndex.url;
             return (
               <tr key={nuforcIndex.id}>
                 <td><Link to={detailUrl}>{nuforcIndex.date}</Link></td>
                 <td>{nuforcIndex.import_status}</td>
               </tr>
             )
          })}
          </tbody>
          </table>
        </Col>
        <Col md={6}>
          <ReportList indexURL={indexURL} />
        </Col>
      </Row>
    </Container>
  )
}


function ReportList({indexURL}) {
    const [reports, setReports] = useState([])
    useEffect(() => {
        fetch(process.env.REACT_APP_UFOX_API_URL + "/api/nuforc-indexes/" + indexURL, { credentials: "include" })
          .then((response) => {
            if (!response.ok) {
              console.log("Error retrieving reports for NUFORC index " + indexURL + ".")
              console.log(response)
              return []
            }

            response.json().then((nuforc_reports_json) => {
              setReports(nuforc_reports_json)
            })
          })
    }, [indexURL])

    if (indexURL !== "") {

      window.scrollTo(0,0)

      return (
      <>
      <table>
        <thead>
        <tr>
          <th colSpan={4}>{indexURL}</th>
        </tr>
        <tr>
          <th>ID</th>
          <th>Date</th>
          <th>Shape</th>
          <th>Location</th>
        </tr>
        </thead>
        <tbody>
        { reports.map((report, idx) => {
          var eventDate = ""
          if (report.event_date) {
              const rawEventDate = Date.parse(report.event_date)
              eventDate = new Date(rawEventDate).toLocaleDateString()
          }
          return (<tr key={report.report_id}>
            <td>{report.report_id}</td>
            <td>{eventDate}</td>
            <td>{report.shape}</td>
            <td>{report.city}, {report.state}</td>
          </tr>
          )
        })}
        </tbody>
      </table>
      </>
      )
    } else {
      return "";
    }
}

export default NUFORCAdmin;
