import React from 'react';
import ReactDOM from 'react-dom/client';
import{ BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css';
import App from './App';
import Home from './Home';
import NyanViz from './NyanViz';
import Semantle from './Semantle';
import Profile from './Profile';
import Admin from './Admin';
import ReportForm from './ReportForm';
import GraphTest from './GraphTest';
import MultiGraphTest from './MultiGraphTest';
import NUFORCAdmin from './NUFORCAdmin';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/pwchange" element={<App />} />
      <Route path="/" element={<App />}>
        <Route path="" element={<Home />} />
        <Route path="nyanviz" element={<NyanViz />} />
        <Route path="semantle" element={<Semantle />} />
        <Route path="profile" element={<Profile />} />
        <Route path="admin" element={<Admin />} />
        <Route path="report-form" element={<ReportForm />} />
        <Route path="graph-test" element={<GraphTest />} />
        <Route path="multi-graph-test" element={<MultiGraphTest />} />
        <Route path="nuforc-admin" element={<NUFORCAdmin />}>
          <Route path=":index_url" element={<NUFORCAdmin />} />
        </Route>
      </Route>
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
