import { useState, useEffect } from 'react';
import $ from "jquery"
import Chart from "chart.js"

function NyanHabitGraph({ data_url, dom_id, labels_title, color, title }) {

    var nyanHabitChart;

    useEffect(() => {
      do_an_ajax_thing(process.env.REACT_APP_UFOX_API_URL + "" + data_url, function(data, processed_data) {
       handleNyanHabitReport(data, dom_id, labels_title, title, processed_data.labels, processed_data.counts);
      });
    });

    function do_an_ajax_thing(ajax_url, handler_func) {
        $.ajax({
            dataType: "json",
            url: ajax_url,
            xhrFields: {
                withCredentials: true
            },
            success: function(data, status, xhr) {
                console.log("Hi!");
                handler_func(data, data);
            },
            error: function(xhr, textStatus, error) {
                console.log("BROKEN by " + textStatus);
                console.log(xhr.statusCode());
            }
        });
    }

    function handleNyanHabitReport(data, target_html_selector) {
        var chart_users = [ ];
        var chart_total_counts = [ ];
        var chart_epic_counts = [ ];
        var chart_tired_counts = [ ];
        var chart_standard_counts = [ ];

        var ctx = $("#" + dom_id)

        for (var i = 0; i < data.length; i++) {
            console.log("#" + i + " " + data[i].user);
            chart_users = chart_users.concat(data[i].user);
            chart_total_counts = chart_total_counts.concat(data[i].total_count);
            chart_epic_counts = chart_epic_counts.concat(data[i].epic_count);
            chart_tired_counts = chart_tired_counts.concat(data[i].tired_count);
            chart_standard_counts = chart_standard_counts.concat(
                data[i].total_count - data[i].epic_count - data[i].tired_count
            );
        }

        console.log("Chart users are now: " + chart_users);
        console.log("Chart counts are now: " + chart_total_counts);
        console.log("Chart epic counts are now: " + chart_epic_counts);
        console.log("Chart tired counts are now: " + chart_tired_counts);
        console.log("Chart standard counts are now: " + chart_standard_counts);

        if (typeof nyanHabitChart == 'undefined') {
            nyanHabitChart = new Chart(ctx, {
                type: 'bar',
    
                data: {
                    labels: chart_users,
                    datasets: [
                    {
                        label: "Standard",
                        backgroundColor: "#57688B",
                        data: chart_standard_counts
                    },
                    {
                        label: "Epic",
                        backgroundColor: "#C364CC",
                        data: chart_epic_counts
                    },
                    {
                        label: "Tired",
                        backgroundColor: "#1B998B",
                        data: chart_tired_counts
                    }
                    ]
                },
    
                options: {
                    legend: {
                        position: 'right'
                    },
                    title: {
                        display: true,
                        text: 'Nyan Habits by User',
                        fontSize: 23
                    },
                    scales: {
                        yAxes: [{
                            display: true,
                            ticks: {
                                beginAtZero: true
                            },
                            stacked: true
                        }],
                        xAxes: [{
                            stacked: true
                        }]
                    }
                }
            });
        }
        nyanHabitChart.data.labels = chart_users;
        nyanHabitChart.data.datasets[0].data = chart_standard_counts;
        nyanHabitChart.data.datasets[1].data = chart_epic_counts;
        nyanHabitChart.data.datasets[2].data = chart_tired_counts;
        nyanHabitChart.update();
    }

    return (
        <canvas id={dom_id} className="ufox-bg-chart"></canvas>
    );

}

export default NyanHabitGraph;


