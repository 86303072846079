import { useEffect, useState } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { parseISO } from "date-fns"

function LastModified() {
    const [lastModified, setLastModified] =  useState("")


    useEffect(() => {
        console.log("Nothing is new under the sun")
	fetch(process.env.REACT_APP_UFOX_API_URL + "/api/newest-nuforc-index")
	   .then( (res) => {
               console.log("The api has given me a nuforc index");

	       console.log(res);
	       res.json().then((the_obj) => {
		   console.log(the_obj)
	           const rawDate = parseISO(the_obj.date);
		   console.log("Hello I have parsed the_obj.date into this:")
		   console.log(rawDate)
		   const humanizedDate = rawDate.toLocaleDateString()
		   console.log(humanizedDate)
		   setLastModified(humanizedDate)
	       });
            });
	});

    return (
        <>
	{lastModified}<br />
	</>
    )
}


export default LastModified;

