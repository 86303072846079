import { useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap"
import $ from "jquery"
import Chart from "chart.js"

import './graphs.css'

function MainGraphGrid() {
    return (
      <Container>
        <Row>
            <Col md={6}>
                <CountGraph data_url="/api/shape_counts?max_results=10" dom_id="myShapeChart" field_name="shape" color="#57688B" title="Shapes" />
            </Col>
            <Col md={6}>
                <CountGraph data_url="/api/city_counts?max_results=10" dom_id="myCityChart" field_name="city" color="#C364CC" title="Cities" />
            </Col>
        </Row>
        <Row>
            <Col md={6}>
                <CountGraph data_url="/api/date_counts?max_results=10" dom_id="myDateChart" field_name="event_date" color="#8B6D57" title="Top 10 Dates" />
            </Col>
            <Col md={6}>
                <CountGraph data_url="/api/month_counts" dom_id="myMonthChart" field_name="month" color="#1B998B" title="Months" />
            </Col>
        </Row>
        <Row>
            <Col md={6}>
                <CountGraph data_url="/api/year_counts?max_results=10" dom_id="myYearChart" field_name="year" color="#7D60AC" title="Years" />
            </Col>
            <Col md={6}>
                <CountGraph data_url="/api/state_counts?max_results=10" dom_id="myStateChart" field_name="state" color="#C7C157" title="States" />
            </Col>
        </Row>
        <Row>
            <Col md={6}>
                <CountGraph data_url="/api/country_counts?max_results=10" dom_id="myCountryChart" field_name="country" color="#C7C157" title="Countries" x_scale_type="logarithmic"/>
            </Col>
        </Row>
      </Container>
    );
}

function CountGraph({ data_url, field_name, dom_id, color, title, x_scale_type="linear" }) {

    useEffect(() => {
      do_an_ajax_thing(process.env.REACT_APP_UFOX_API_URL + "" + data_url, handleThingCounts)
    },[ data_url, field_name ]);

    function do_an_ajax_thing(ajax_url, handler_func) {
        $.ajax({
            dataType: "json",
            url: ajax_url,
            xhrFields: {
                withCredentials: true
            },
            success: function(data, status, xhr) {
                console.log("Hi!");
                handler_func(data);
            },
            error: function(xhr, textStatus, error) {
                console.log("BROKEN by " + textStatus);
                console.log(xhr.statusCode());
            }
        });
    }

    function handleThingCounts(thingCountData) {

        chart_labels = [];
        chart_counts = [];

        for (var i = 0; i < thingCountData.length; i++) {

            var chart_labels = chart_labels.concat(thingCountData[i][field_name]);
            var chart_counts = chart_counts.concat(thingCountData[i].count);
            console.log("I'll be using field_name " + field_name + "!")
            console.log("A thing is " + thingCountData[i][field_name] + " with count " + thingCountData[i].count);
        }
        console.log("Chart labels are now: " + chart_labels);
        console.log("Chart counts are now: " + chart_counts);

        /// Create a chart with thing data
        var ctx = $("#" + dom_id)
        console.log("context:")
        console.log(ctx)
        var thingChart = new Chart(ctx, {
            // The type of chart
            type: 'horizontalBar',

            // The data for our dataset
            data: {
                labels: chart_labels,
                datasets: [{
                    label: title,
                    backgroundColor: color,
                    borderColor: "E9ECF5",
                    barThickness: "flex",
                    data: chart_counts,
                }]
            },
            // Configuration options
            options: {
                legend: {
                    display: false
                },
                title: {
                    display: true,
                    text: title,
                    fontSize: 23
                },
                scales: {
                    xAxes: [{
                        display: true,
                        ticks:{
                            beginAtZero: true
                        },
                        stacked: true,
                        type: x_scale_type
                    }]
                }
            }
        });
    }

    return (
        <canvas id={dom_id} className="ufox-bg-chart"></canvas>
    );
}

export default MainGraphGrid;
export { CountGraph };
