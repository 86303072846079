import io from 'socket.io-client'

import { useContext, useEffect, useState } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { Alert} from "react-bootstrap"
import $ from "jquery"
import Chart from "chart.js"

import { UFOXTitleContext } from "./App"

import NyanHabitGraph from './NyanHabitGraph';

import './graphs.css'

function NyanViz() {
  const [title, setTitle] = useContext(UFOXTitleContext)
  useEffect(() => setTitle("Nyan Habits"));

  const [socket, setSocket] = useState(null)

  const [alertData, setAlertData] = useState([]);
  useEffect(() => {
      const newSocket = io(process.env.REACT_APP_UFOX_WS_URL);
      newSocket.on('connect', function() {
        console.log("Compliments to the horse.")
        newSocket.send("Hey, nice horse!.");
      });
      newSocket.on('message', function(data) {
        console.log("I got a message!");
        console.log(data);
        const data_obj = JSON.parse(data);
        if ('text' in data_obj) {
          setAlertData([...alertData, data_obj]);
        }
      });
      setSocket(newSocket)
  }, []);

  return (
    <div className="App">
      <Container>
        <Row>
          <Col md={12}>
            <div id="ufdiscord_notification_container">
              {alertData.map((obj, i) => (
                <NyanAlert image={obj.image_name}>{obj.text}</NyanAlert>
              ))}
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <NyanHabitGraph data_url="/nyan/api/habit_report" dom_id="nyanHabitChart" />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <NyanHoursGraph data_url="/nyan/api/habit_hours" dom_id="nyanHoursChart" labels_title="Attempts" color="#57688B" title="Nyan Attempts by Hour" />
          </Col>
          <Col md={6}>
            <NyanDaysGraph data_url="/nyan/api/habit_days_of_week" dom_id="nyanDaysOfWeekChart" labels_title="Attempts" color="#57688B" title="Nyan Attempts by Day of Week" />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

function NyanAlert({ children, image=null }) {
  const image_url = process.env.REACT_APP_UFOX_API_URL + "/nyan/images/" + image;
  const image_tag = <img width={96} style={{paddingRight: "20px"}} src={image_url} />

  const [show, setShow] = useState(true);
  function closeMe(evt) {
      console.log(evt);
      setShow(false);
  }
  if (show) {
      return (
        <Alert className="ufox-bg-chart" style={{textAlign: "left"}} variant="dark" dismissible onClose={(e) => closeMe(e)}>
          {image_tag}
          {children}
        </Alert>
      );
  } else {
      return "";
  }
}

function NyanLineGraph({ data_url, dom_id, labels_title, color, title, pre_handler_func }) {

    useEffect(() => {
      do_an_ajax_thing(process.env.REACT_APP_UFOX_API_URL + "" + data_url, function(data, processed_data) {
       handleStandardNyanReport(data, dom_id, labels_title, title, processed_data.labels, processed_data.counts);
      });
    },[]);

    function do_an_ajax_thing(ajax_url, handler_func) {
        $.ajax({
            dataType: "json",
            url: ajax_url,
            xhrFields: {
                withCredentials: true
            },
            success: function(data, status, xhr) {
                console.log("Hi!");
                handler_func(data, pre_handler_func(data));
            },
            error: function(xhr, textStatus, error) {
                console.log("BROKEN by " + textStatus);
                console.log(xhr.statusCode());
            }
        });
    }

    function handleStandardNyanReport(data, target_html_selector, labels_title, chart_title, chart_labels, chart_counts) {
        var ctx = $("#" + dom_id)
    
        if (true) {
            const nyanStandardChart = new Chart(ctx, {
                type: 'line',
                data: {
                    labels: chart_labels,
                    datasets: [
                    {
                        label: labels_title,
                        data: chart_counts,
                        borderColor: '#57688B',
                        fill: false
                    }
                    ]
                },
                options: {
                    legend: {
                        display: false
                    },
                    title: {
                        display: true,
                        text: chart_title,
                        fontSize: 23
                    },
                    scales: {
                        yAxes: [{
                            display: true,
                            ticks: {
                                beginAtZero: true
                            }
                        }],
                        xAxes: [{
                            display: true
                        }]
                    }
                }
    
            });
            nyanStandardChart.data.labels = chart_labels;
            nyanStandardChart.data.datasets[0].data = chart_counts;
            nyanStandardChart.update();
            console.log("WE MADE IT On Some Standard Chart");
        }
    }

    return (
        <canvas id={dom_id} className="ufox-bg-chart"></canvas>
    );
}

function NyanHoursGraph({ data_url, dom_id, labels_title, color, title }) {

  function nyanHoursPreHandler(data) {
    var chart_labels = [ ]
    var chart_counts = [ ]
    for (var i = 0; i < 24; i++) {
      chart_labels.push(i);
      chart_counts.push(data[i]);
    }
    return { "labels": chart_labels, "counts": chart_counts }
  }

  return (
    <NyanLineGraph data_url={data_url} dom_id={dom_id} labels_title={labels_title} color={color} title={title} pre_handler_func={nyanHoursPreHandler} />
  );
}

function NyanDaysGraph({ data_url, dom_id, labels_title, color, title }) {

  function nyanDaysPreHandler(data) {
    var chart_labels = [ ]
    var chart_counts = [ ]
    for (var i = 0; i < 7; i++) {
      chart_labels.push(data[i][1]);
      chart_counts.push(data[i][2]);
    }
    return { "labels": chart_labels, "counts": chart_counts }
  }

  return (
    <NyanLineGraph data_url={data_url} dom_id={dom_id} labels_title={labels_title} color={color} title={title} pre_handler_func={nyanDaysPreHandler} />
  );
}

export default NyanViz;
