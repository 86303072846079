import io from 'socket.io-client'

import { useContext, useEffect, useState } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { Alert } from "react-bootstrap"

import { UFOXTitleContext } from "./App"

function Semantle() {
  const [title, setTitle] = useContext(UFOXTitleContext)

  const [leaderboard, setLeaderboard] = useState([])
  const [assistboard, setAssistboard] = useState([])

  useEffect(() => setTitle("Semantle"));

  useEffect(() => {
      fetch(process.env.REACT_APP_UFOX_API_URL + "/semantle/api/leaderboard", { credentials: "include" })
        .then((response) => {
          if (!response.ok) {
            console.log("Error retrieving Semantle leaderboard.")
            console.log(response)
            return []
          }

          response.json().then((leaderboard_json) => {
            setLeaderboard(leaderboard_json)
          })
        })
  }, [])

  useEffect(() => {
      fetch(process.env.REACT_APP_UFOX_API_URL + "/semantle/api/assistboard", { credentials: "include" })
        .then((response) => {
          if (!response.ok) {
            console.log("Error retrieving Semantle assistboard.")
            console.log(response)
            return []
          }

          response.json().then((assistboard_json) => {
            setAssistboard(assistboard_json)
          })
        })
  }, [])


  const [socket, setSocket] = useState(null)

  const [alertData, setAlertData] = useState([]);
  useEffect(() => {
      const newSocket = io(process.env.REACT_APP_UFOX_WS_URL);
      newSocket.on('connect', function() {
        console.log("Compliments to the horse.")
        newSocket.send("Hey, nice horse!.");
      });
      newSocket.on('message', function(data) {
        console.log("I got a message!");
        console.log(data);
        const data_obj = JSON.parse(data);
        console.log(data_obj)
        if ('puzzle_num' in data_obj) {
          setAlertData([...alertData, data_obj]);
        }
      });
      setSocket(newSocket)
  }, []);

  return (
    <div className="App">
      <Container>
        <Row>
          <Col md={12}>
            <div id="ufdiscord_notification_container">
              {alertData.map((obj, i) => (
                <SemantleAlert key={obj.puzzle_num}>Puzzle Number {obj.puzzle_num} has Team Code {obj.team_code}</SemantleAlert>
              ))}
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <div style={{textAlign: "left", fontSize: "24px", fontWeight: "bold", color: "#000000", background: "#dddddd", padding: "5px"}}>Leaderboard</div>
            <table className="ufox-bg-chart" style={{color: "#23346E"}}>
            <thead>
              <tr><th>Player</th><th>Solves</th></tr>
            </thead>
            <tbody>
            { leaderboard.map((entry, idx) => {
               return (
                 <tr key={entry[0]}><td>{entry[0]}</td><td style={{textAlign: "right"}}>{entry[1]}</td></tr>
               );
            })}
            </tbody>
            </table>
          </Col>
          <Col md={3}>
            <div style={{textAlign: "left", fontSize: "24px", fontWeight: "bold", color: "#000000", background: "#dddddd", padding: "5px"}}>Assistboard</div>
            <table className="ufox-bg-chart" style={{color: "#23346E"}}>
            <thead>
              <tr><th>Player</th><th>Solves</th></tr>
            </thead>
            <tbody>
            { assistboard.map((entry, idx) => {
               return (
                 <tr key={entry[0]}><td>{entry[0]}</td><td style={{textAlign: "right"}}>{entry[1]}</td></tr>
               );
            })}
            </tbody>
            </table>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

function SemantleAlert({ children, image=null }) {

  const [show, setShow] = useState(true);
  function closeMe(evt) {
      console.log(evt);
      setShow(false);
  }
  if (show) {
      return (
        <Alert style={{textAlign: "left"}} variant="dark" dismissible onClose={(e) => closeMe(e)}>
          {children}
        </Alert>
      );
  } else {
      return "";
  }
}

export default Semantle;

