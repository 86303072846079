import './App.css'
import { useContext } from "react"
import { Container, Row, Col } from 'react-bootstrap';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { NavLink } from 'react-router-dom'

import { UFOXUserContext } from "./App"

function MainMenu() {
  const [ufoxUser, setUfoxUser] = useContext(UFOXUserContext)
  var navItems = [
  ];
  if (ufoxUser && Object.keys(ufoxUser).length > 0) {
    navItems = [ ...navItems,
      <Nav.Item key="home">
        <Nav.Link to="/" as={NavLink}>Home</Nav.Link>
      </Nav.Item>,
      <Nav.Item key="nyanviz">
        <Nav.Link to="/nyanviz" as={NavLink}>NyanViz</Nav.Link>
      </Nav.Item>,
      <Nav.Item key="profile">
        <Nav.Link to="/profile" as={NavLink}>Profile</Nav.Link>
      </Nav.Item>,
      <Nav.Item key="admin">
        <Nav.Link to="/admin" as={NavLink}>Admin</Nav.Link>
      </Nav.Item>,
      <Nav.Item key="report-form">
        <Nav.Link to="/report-form" as={NavLink}>Report Form</Nav.Link>
      </Nav.Item>,
      <Nav.Item key="nuforc-admin">
        <Nav.Link to="/nuforc-admin" as={NavLink}>NUFORC Admin</Nav.Link>
      </Nav.Item>
    ];
  }

  return (
    <Navbar expand="sm" bg="dark" variant="dark" sticky="top" style={{paddingLeft: "15px", paddingRight: "15px"}}>
      <Navbar.Brand>UFO Exchange</Navbar.Brand>
      <Navbar.Toggle aria-controls="navbarSupportedContent" aria-expanded="false" />
      <Navbar.Collapse id="navbarSupportedContent">
        <Nav className="mr-auto">
        { navItems }
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default MainMenu;
