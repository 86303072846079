import { useContext, useEffect } from "react"

import './App.css'
import MainGraphGrid from './MainGraphGrid';

import { UFOXTitleContext } from "./App"

function Home() {
  const [title, setTitle] = useContext(UFOXTitleContext)
  useEffect(() => setTitle("UFO Exchange"))

  return (
    <div className="App">
      <MainGraphGrid />
    </div>
  );
}

export default Home;
