
import { Container, Row, Col } from "react-bootstrap"
import LastModified from './LastModified';

function UFOXFooter() {
  return (
    <Container>
      <Row>
        <Col id="footer-cell" className="body-grid-cell">UFO sighting data from National UFO Reporting Center, last imported <LastModified /></Col>
      </Row>
    </Container>
  );
}

export default UFOXFooter;
