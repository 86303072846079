import React, { useContext, useEffect, useState } from 'react'
import { Container, Row, Col } from "react-bootstrap"
import { Button, Form, Alert } from "react-bootstrap"
import { Outlet, useSearchParams } from "react-router-dom";

import './App.css'
import UFOXHeader from './UFOXHeader';
import UFOXFooter from './UFOXFooter';
import MainMenu from './MainMenu';

export const UFOXUserContext = React.createContext({})
export const UFOXTitleContext = React.createContext({})

function App() {
  const [user, setUser] = useState(null)
  const [title, setTitle] = useState("")
  const [footer, setFooter] = useState("")
  useEffect(() => {
  if (!user) {
    setTitle("")
    setFooter("")
    fetch(process.env.REACT_APP_UFOX_API_URL + "/api/profile", {credentials: "include"})
      .then((res) => {
        if(res.ok && res.headers.get("content-type") == "application/json") {
            console.log("Res status is " + res.status)
            res.json().then((user_obj) => {
              console.log("Hello, legit user.")
              console.log(user_obj)
              setUser(user_obj)
            })
          } else {
              console.log("This is not the profile of a logged in user.")
              setUser({})
          }
      })
    console.log("Set user:")
    console.log(user)
  }
  })

  return (
    <div className="App">
      <UFOXTitleContext.Provider value={[title, setTitle]}>
        <UFOXUserContext.Provider value={[user, setUser]}>
            <MainMenu />
            <UFOXHeader pagetitle={title} />
            <Outlet />
            <UFOXFooter />
        </UFOXUserContext.Provider>
      </UFOXTitleContext.Provider>
    </div>
  );
}

function LoginRequired({children, user, userSetter }) {
  const [title, setTitle] = useContext(UFOXTitleContext);
  useEffect(() => {
    if (user && Object.keys(user).length == 0) {
      setTitle("UFO Exchange");
    }
  });
  if (!user) {
      return ""
  } else if (Object.keys(user).length > 0) {
      return (children)
  } else {
      return (
        <Container>
          <Row>
            <Col md={4} style={{ textAlign: "left" }}>
              <LoginForm userSetter={userSetter} />
            </Col>
            <Col md={4} />
            <Col md={4} />
          </Row>
        </Container>
      ); 
  }
}

function LoginForm({ userSetter=null }) {
    const [ username, setUsername ] = useState(null)
    const [ password, setPassword ] = useState(null)

    const [ email, setEmail ] = useState(null)

    const [ loginFailed, setLoginFailed ] = useState(false)

    const [queryParams, setQueryParams] = useSearchParams()
    var defaultFormState = "login"
    if (queryParams.get('recovery_code')) {
        defaultFormState = "pwchange"
    }

    function doLogin(evt) {
      evt.preventDefault()
      console.log(evt)
      const loginFormData = new FormData()
      loginFormData.append("username", username)
      loginFormData.append("password", password)
      loginFormData.append("next", "/api/profile")
      fetch(process.env.REACT_APP_UFOX_API_URL + "/login", {credentials: "include", method: "POST", body: loginFormData})
          .then((response) => {
            console.log(response)
            if(response.ok && response.headers.get("content-type") == "application/json") {
              response.json().then((user_obj) => {
                console.log("Hello, newly logged-in legit user.")
                console.log(user_obj)
                userSetter(user_obj)
                // window.location.reload(true);
              })
            } else {
              console.log("Tell the user they fucked up again.");
              console.log("The event that told me so is");
              console.log(evt);
              setLoginFailed(true)
            } 
          })
      return false;
    }

    function doRemind(evt) {
        evt.preventDefault();
        const loginFormData = new FormData()
        loginFormData.append("email", email)
        fetch(process.env.REACT_APP_UFOX_API_URL + "/pwreminder", {method: "POST", body: loginFormData})
            .then((response) => {
              console.log(response)
              if(response.ok) {
                showLoginForm(evt);
              } else {
                console.log("Tell the user they fucked up again.");
                console.log("The event that told me so is");
                console.log(evt);
                alert("There was a server error trying to send you a password reminder.")
              } 
            })
    }

    function doPasswordChange(evt) {
        evt.preventDefault();
        const passwordChangeFormData = new FormData()
        passwordChangeFormData.append("recovery_code", queryParams.get("recovery_code"))
        passwordChangeFormData.append("password", password)
        fetch(process.env.REACT_APP_UFOX_API_URL + "/pwreminder", {method: "POST", body: passwordChangeFormData})
            .then((response) => {
              console.log(response)
              if(response.ok) {
                showLoginForm(evt);
              } else {
                console.log("Tell the user they fucked up again.");
                console.log("The event that told me so is");
                console.log(evt);
                alert("There was a server error trying to change your password.")
              } 
            })
    }


    const [formMode, setFormMode] = useState(defaultFormState)

    function showLoginForm(evt) {
        evt.preventDefault();
        setUsername("")
        setPassword("")
        setEmail("")
        setFormMode('login')
    }

    function showPasswordRemindForm(evt) {
        evt.preventDefault();
        setUsername("")
        setPassword("")
        setEmail("")
        setFormMode('remind')
    }

    if (formMode === 'login') {
        return (
          <Form action="#" onSubmit={doLogin}>
            <Alert variant="danger" show={loginFailed}>Unknown username or incorrect password. Please try again.</Alert>
            <Form.Group className="mb-3" controlId="usernameField">
              <Form.Label>Username</Form.Label>
              <Form.Control 
                name="username"
                type="text"
                required
                placeholder="jimmyjo"
                onBlur={ e => setUsername(e.target.value) }
                onChange={ e => setUsername(e.target.value) }/>
            </Form.Group>

            <Form.Group className="mb-3" controlId="passwordField">
              <Form.Label>Password</Form.Label>
              <Form.Control
                name="password"
                type="password"
                required
                placeholder="12345"
                onBlur={ e => setPassword(e.target.value) }
                onChange={ e => setPassword(e.target.value) }/>
            </Form.Group>

            <Button className="btn btn-primary btn-large centerButton" type="submit">Login</Button><br />
            <a href="#" onClick={ e => showPasswordRemindForm(e) }>Forgot Password?</a><br />
            <DebugPanel username={username} password={password} />
          </Form>
        );
    } else if (formMode === 'remind') {
        return (
          <Form action="#" onSubmit={ doRemind }>
            <Form.Group className="mb-3" controlId="emailField">
              <Form.Label>Email</Form.Label>
              <Form.Control 
                name="email"
                type="email"
                required
                placeholder="jim@jimmy.jo"
                onBlur={ e => setEmail(e.target.value) }
                onChange={ e => setEmail(e.target.value) }/>
            </Form.Group>

            <Button className="btn btn-primary btn-large centerButton" type="submit">Remind Me</Button><br />
            <a href="#" onClick={ e => showLoginForm(e) }>Login</a><br />
          </Form>
        );
    } else if (formMode === 'pwchange') {
        return (
          <Form action="#" onSubmit={ doPasswordChange}>
            <Form.Group className="mb-3" controlId="passwordField">
              <Form.Label>New Password</Form.Label>
              <Form.Control 
                name="password"
                type="password"
                required
                placeholder="12345"
                onBlur={ e => setPassword(e.target.value) }
                onChange={ e => setPassword(e.target.value) }/>
            </Form.Group>

            <Button className="btn btn-primary btn-large centerButton" type="submit">Set Password</Button><br />
            <a href="#" onClick={ e => showLoginForm(e) }>Login</a><br />
          </Form>
        );
    }
}

function DebugPanel({ username, password }) {
  const [queryParams, setQueryParams] = useSearchParams()
  if (queryParams.get("debug") == "true") {
    return (
      <div style={{ padding: "10px", marginTop: "10px"}}>
        <div style={{textAlign: "left", backgroundColor: "#DDDDDD", color: "#333333", padding: "10px"}}>
          Debug Panel
        </div>
        <div style={{textAlign: "left", backgroundColor: "#BBBBBB", color: "#333333", padding: "10px"}}>
          Username is currently: { username }<br />
          Password is currently: { password }<br />
        </div>
      </div>
    )
  } else {
    return ("")
  }
}

export default App;
export { LoginRequired };
