import { useContext, useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { Button } from "react-bootstrap"

import { LoginRequired, UFOXTitleContext } from "./App"

function Admin() {
  const [title, setTitle] = useContext(UFOXTitleContext)
  useEffect(() => setTitle("UFOX Admin Controls"));

  function doResetCache(evt) {
    evt.preventDefault()
    console.log("I'll reset the cache.");
    fetch(process.env.REACT_APP_UFOX_API_URL + "/api/resetcache", {credentials: "include", method: "POST"})
        .then((response) => console.log(response))
  }

  return (
    <LoginRequired>
      <Container>
        <Row>
          <Col md="4">
            <Button variant="outline-dark" onClick={(e) => doResetCache(e)}>Reset Cache</Button>
          </Col>
          <Col md="8">
          </Col>
        </Row>
      </Container>
    </LoginRequired>
  );
}

export default Admin;
