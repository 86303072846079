import { useEffect, useState } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { CountGraph } from "./MainGraphGrid"
import { Button, Form } from "react-bootstrap"

import './graphs.css'

function GraphTest() {
    const [url, setURL] = useState("/api/country_counts?max_results=10")
    const [graphLabelField, setGraphLabelField] = useState("country")
    const [graphTitle, setGraphTitle] = useState("Countries")
    const [finalURL, setFinalURL ] = useState(null)
    const [finalGraphLabelField, setFinalGraphLabelField] = useState(null)

    function reloadGraphData(evt) {
      evt.preventDefault()
      setFinalURL(url)
      setFinalGraphLabelField(graphLabelField)
      console.log("Reloading Graph Data from URL: " + url)
    }

    return (
      <Container>
        <Row>
            <Col md={12}>
                <CountGraph data_url={ finalURL } dom_id="myChart" field_name={ finalGraphLabelField } color="#57688B" title="Countries" x_scale_type="logarithmic" />
            </Col>
        </Row>

        <Row>
            <Col md={12}>
	    {/* Form control to query max results as a smoke test */}
	    <Form onSubmit={ reloadGraphData }>
                <Form.Group className="mb-3" controlId="urlField">
                  <Form.Label>URL</Form.Label>
                  <Form.Control
                    type="text"
                    maxLength="60"
	            size="80"
                    onChange={ e => setURL(e.target.value) }
	            value={ url }>
                  </Form.Control>
                </Form.Group>

                <Form.Group className="mb-3" controlId="graphLabelField">
                  <Form.Label>Graph Label Field</Form.Label>
                  <Form.Control
                    type="text"
                    maxLength="60"
	            size="80"
                    onChange={ e => setGraphLabelField(e.target.value) }
	            value={ graphLabelField }>
                  </Form.Control>
                </Form.Group>

                <Button className="btn btn-primary btn-large centerButton" type="submit">Reload</Button>

	    </Form>
            </Col>
        </Row>
      </Container>
    );
}

export default GraphTest;

{/* TODO: 
	  List of basic graph types w/ baseURL and/or max_results, etc.
	  Pull down menu with 7 itmes
	  Make a form with user friendly fields that turn into a URL onSubmit() */}
